<template>
	<Table :name="name"
		   :fields="fields"
		   :action="action"
		   @checkUncheckItem="checkUncheckItem"
		   :checked-items="checkedItems"
		   :pageSizeLabel="pageSizeLabel"
		   :deleteAction="deleteAction"
		   ref="table"
	>
		<template #more_actions>
			<slot name="more_actions"></slot>
		</template>

		<template #actions="row">
			<slot name="actions" :item="row.item">
			</slot>
		</template>

	</Table>
</template>

<script>
    import Table from "../../../../../../shared/components/Table";
    import {mapGetters} from "vuex";

    export default {

        name: "LeadsTable",
        components: {Table},
        data() {
            return {
                pageSizeLabel: 'Leads per page',
            }
        },
        props: {
            name: {
                type: String,
                required: true
            },
            action: {
                type: String,
                required: true
            },
            deleteAction: {
                type: String,
                required: true
            },
            showActions: {
                type: Boolean,
                required: false,
				default: true
            },
            fields: {
                type: Array,
                required: true
            },
        },
        methods: {
            checkUncheckItem(itemId, checked) {
                this.$store.dispatch("leads/addOrDeleteCheckedItem", {
                    itemId: itemId,
                    checked: checked,
                    type: this.name
                });
            },
			refresh() {
                this.$refs.table.refresh();
			}

        },
        computed: {
            checkedItems() {
                return this.stateCheckedItems[this.name];
            },
            checkedItemsCount() {
                return this.checkedItems && this.checkedItems.length;
            },
            ...mapGetters({
                stateCheckedItems: "leads/getCheckedItems"
            })
        }
    }
</script>
