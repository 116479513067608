<template>
	<section class="billing-cnt">
		<div class="container">
			<div class="row">
				<div class="col-md-4">
					<div :class="`settings-item settings-item--${typeColor} billing-item`">
						<template v-if="!subscriptionLoaded">
							<Loading/>
						</template>
						<template v-else>
							<div class="settings-item__img"><img
									:src="require('@shared/assets/styles/images/card-' + typeColor + '.svg')" alt=""></div>
							<p class="settings-item__subtitle">Current Plan</p>
							<h3 class="settings-item__title">{{currentPlan.display_name}}</h3>
							<p class="settings-item__txt">${{ isOnYearly ?  (Math.round((currentPlan.price / 12) * 100, 2) / 100) : currentPlan.price}}/month</p>
							<div class="settings-item__btns">
								<router-link tag="a"
											 class="settings-item__btn btn btn-outline-secondary"
											 :to="{name: 'dashboard.pick-plan'}">Upgrade Plan
								</router-link>
							</div>
                            <p class="settings-item__txt">
                                To cancel your subscription, please contact us through chat or drop an email at support@siteauditor.com
                            </p>
							<div :class="`billing-info billing-info--${typeColor}`">
								<p class="billing-info__txt">{{dateMessage}}</p>
								<p class="billing-info__date">{{currentPlan.date}}</p>
							</div>
						</template>
					</div>
				</div>
				<div class="col-md-4">
					<div class="settings-item">
						<div class="settings-item__img"><img src="../../../../../shared/assets/styles/images/card.svg" alt=""></div>
						<h3 class="settings-item__title">Payment Method</h3>
						<template v-if="!cardInfoLoaded || !subscriptionLoaded">
							<Loading/>
						</template>
						<form v-else class="settings-item__form" @submit.prevent="saveNewCard">
							<div class="form-group">
								<label for="settingsCard">Card Number</label>
								<input v-if="isOnTrial" class="form-control" id="settingsCard" type="text" disabled>
								<div v-else class="form-control" id="cardNumber" type="text"></div>
							</div>
							<div class="settings-item__form-groups">
								<div class="form-group">
									<label for="settingsDate">Expiration Date</label>
									<input v-if="isOnTrial" class="form-control" id="settingsDate" type="text" disabled>

									<div v-else class="form-control" id="cardExpiry" type="text"></div>

								</div>
								<div class="form-group">
									<label for="settingsCvc">CVC</label>
									<input v-if="isOnTrial" class="form-control" id="settingsCvc" type="text" disabled>

									<div v-else class="form-control" id="cardCvc" type="text"></div>
								</div>
							</div>
							<input class="btn btn-secondary" type="submit" :disabled="isOnTrial || paymentButtonDisabled" value="Save">
							<div v-if="cardError" id="card-error">{{cardError}}</div>
						</form>
					</div>
					<div v-if="lastChargeFailed" class="billing-info billing-info--red billing-info--message">
						<p class="billing-info__txt">Please update your card. Your recent charge failed.</p>
					</div>
				</div>
				<div class="col-md-4">
					<Invoices></Invoices>

					<!-- <div class="billing-links" v-if="canCancel">
						<a class="billing-link" @click="cancelSubscriptionModal">Cancel Your Subscription</a>
					</div> -->
				</div>
			</div>
		</div>
		<Modal modal-id="cancel-plan"
			   classNames="modal--plan"
			   :hideButtons="true">
			<p>Are you sure you want to cancel?</p>
			<h2>{{currentPlan.display_name}} Plan</h2>
			<div class="modal-btns">
				<a class="btn btn-danger" @click="cancelSubscription">Yes, Cancel</a>
				<a class="modal-link" @click="closeCancelSubscriptionModal">No, Continue with Plan</a>
			</div>

		</Modal>
		<Modal modal-id="cancel-plan-confirm"
			   classNames="modal--plan"
			   :hideButtons="true">
			<p class="modal-txt modal-txt--red">Your plan has been cancelled</p>
			<p class="modal-txt">Your plan will remain active until the end of this billing cycle.</p>

		</Modal>
	</section>
</template>

<script>
    import {PLAN_TYPE, SUBSCRIPTION_INTERVAL, SUBSCRIPTION_STATUS} from "../../../../../shared/constants";
    import Loading from "../../../../../shared/components/Loading";
    import Invoices from "./components/Invoices";
    import Modal from "../../../../../shared/components/Modal";

    export default {
        name: "Billing",
        components: {Modal, Invoices, Loading},
        data() {
            return {
                cardError: null,
                paymentButtonDisabled: true,
                subscriptionLoaded: false,
                cardInfoLoaded: false,
                lastChargeFailed: false,
                currentPlan: {
                    name: '',
                    display_name: '',
                    status: '',
                    date: null,
                    price: 0,
                    interval: null,
					type: null
                },
                card: {
                    last4: 'xxxx',
                    exp_month: null,
                    exp_year: null,
                }
            }
        },
        mounted() {
            this.getSubscription();
            this.getLastChargeStatus();
        },
        methods: {
            getSubscription() {
                this.$store.dispatch('subscription/getSubscription', this.domain)
                    .then((response) => {
                        this.currentPlan = {
                            name: response.name,
                            display_name: response.display_name,
                            status: response.status,
                            date: response.expire_date,
                            planId: response.plan_id,
                            interval: response.interval,
                            price: response.price,
                            type: response.type
                        };
                        this.subscriptionLoaded = true;
                        this.getCardDetails();

                    }).catch((err) => {
                    this.paymentButtonDisabled = false;
                    this.showErrorNotifications(err);

                })
            },
			getLastChargeStatus() {
                this.$store.dispatch('subscription/getLastChargeSucceeded', this.domain)
                    .then((response) => {
                        this.lastChargeFailed = !response;

                    }).catch((err) => {
                    this.showErrorNotifications(err);
                })

			},
            getCardDetails() {
                this.$store.dispatch('subscription/getCardDetails', this.domain)
                    .then((response) => {
                        this.card.last4 = response.last4;
                        this.card.exp_month = response.exp_month;
                        this.card.exp_year = response.exp_year;
                        this.cardInfoLoaded = true;

                        if (!this.isOnTrial) {
                            this.$nextTick(() => {
                                this.setStripeForm();
                            });
                        }
                    }).catch((err) => {

                    this.showErrorNotifications(err);
                })
            },
            async saveNewCard() {
                if (!this.isOnTrial) {
                    this.paymentButtonDisabled = true;
                    this.cardError = null;
                    try {
                        const intentData = await this.$store.dispatch('subscription/getSetupIntent');
                        const result = await this.$stripe.confirmCardSetup(intentData.client_secret, {
                            payment_method: { card: this.cardNumber }
                        });
                        if (result.error) {
                            this.cardError = result.error.message;
                            this.paymentButtonDisabled = false
                            return;
                        }
                        await this.$store.dispatch('subscription/updatePaymentMethod', {setupIntent: result.setupIntent});
                        this.showNotifications({message: 'Payment Method Successfully updated', type: 'success'});
                        this.destroyStripeElements();
                        this.getCardDetails();
                    } catch (error) {
                        if (error.response && error.response.status === 403) {
                            this.cardError = error.response.data;
                        } else {
                            this.showErrorNotifications(error);
                        }
                    }
                    this.paymentButtonDisabled = false;
                }
            },
            setStripeForm() {
                const style = {
                    base: {
                        color: '#4180c6',
                        fontSize: '13px',
                        lineHeight: '15px',
                        '::placeholder': {
                            color: '#828F9C',
                        },
                    },
                };
                this.cardNumber = this.stripeElements.create('cardNumber', {
                    style: style,
                    placeholder: this.cardNumberPlaceholder,
                });
                this.cardNumber.mount('#cardNumber');

                this.cardExpiry = this.stripeElements.create('cardExpiry', {
                    style: style,
                    placeholder: this.cardExpirationPlaceholder,
                });
                this.cardExpiry.mount('#cardExpiry');

                this.cardCvc = this.stripeElements.create('cardCvc', {
                    style: style,
                    placeholder: 'XXX'
                });
                this.cardCvc.mount('#cardCvc');

                this.$nextTick(() => {
                    this.paymentButtonDisabled = false;
                });


            },
            updateCard(token) {
                this.$store.dispatch('subscription/updateCardDetails', {token: token})
                    .then(() => {
                        this.showNotifications({message: 'Payment Method Successfully updated', type: 'success'});

                        this.destroyStripeElements();
                        this.getCardDetails();

                    }).catch((error) => {
                    this.paymentButtonDisabled = false;
                    if (error.response && error.response.status === 403) {
                        this.cardError = error.response.data;
                    } else {
                        this.showErrorNotifications(error);
                    }
                })
            },
			destroyStripeElements() {
                this.cardNumber.destroy();
                this.cardExpiry.destroy();
                this.cardCvc.destroy();
			},

            cancelSubscriptionModal() {
				if (this.canCancel) {
                    this.$bvModal.show('cancel-plan');
				}

			},

            cancelSubscription() {
				if (this.canCancel) {
                    this.$store.dispatch('subscription/cancelSubscription')
                        .then(() => {
                            this.$bvModal.hide('cancel-plan');
                            this.$bvModal.show('cancel-plan-confirm');
                            this.getSubscription();

                        }).catch((err) => {
                        this.$bvModal.hide('cancel-plan');
                        this.showErrorNotifications(err);
                    })
				}

			},
            closeCancelSubscriptionModal() {
                this.$bvModal.hide('cancel-plan');
			}
        },
        computed: {
            canCancel() {
                return !this.isOnTrial && this.currentPlan.status === SUBSCRIPTION_STATUS.VALID
			},
            typeColor() {
                return this.currentPlan.status === SUBSCRIPTION_STATUS.EXPIRED ? 'red' :
                    (this.currentPlan.status === SUBSCRIPTION_STATUS.CANCELLED ? 'orange' : 'green');
            },
            dateMessage() {
                if (this.currentPlan.status === SUBSCRIPTION_STATUS.CANCELLED) {
                    return 'You cancelled your plan. Your plan will expire on:'
                } else if (this.currentPlan.status === SUBSCRIPTION_STATUS.EXPIRED) {
                    if (this.isOnTrial) {
                        return 'Your free trial expired on:'
                    } else {
                        return 'Your plan expired on:';
                    }
                } else if (this.currentPlan.status === SUBSCRIPTION_STATUS.VALID) {
                    if (this.isOnTrial) {
                        return 'Your free trial will expire on:'
                    } else {
                        return 'Your subscription will renew on:';
                    }
                }
                return '';
            },
            isOnTrial() {
                return this.currentPlan.type ===  PLAN_TYPE.FREE_TRIAL;
            },
            isOnYearly() {
                return  this.currentPlan.interval === SUBSCRIPTION_INTERVAL.YEARLY;
                },
            cardNumberPlaceholder() {
                return 'xxxx xxxx xxxx ' + (this.card.last4 ? this.card.last4 : 'xxxx');
            },
            cardExpirationPlaceholder() {
                if (this.card.exp_month) {
                    let month = this.card.exp_month < 10 ? '0' + this.card.exp_month : '' + this.card.exp_month;
                    let year = this.card.exp_year.toString().slice(-2);

                    return month + '/' + year;
                }
                return 'DD/MM';

            },
            stripeElements() {
                return this.isOnTrial ? null :  this.$stripe.elements();
            },
        },
		beforeDestroy() {
            if (!this.isOnTrial && this.cardNumber) {
                this.destroyStripeElements()
			}

        }
    }
</script>

<style>
	#card-error {
		width: 100%;
		margin-top: 0.25rem;
		font-size: 80%;
		color: #dc3545;
	}
</style>
