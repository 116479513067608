<template>
	<div class="container" style="padding-top: 50px">
		<LeadsTable :name="name" :fields="fields" :action="action" :deleteAction="deleteAction"></LeadsTable>
	</div>
</template>

<script>
    import LeadsTable from "./components/LeadsTable";

    export default {
        name: "MyLeads",

        components: {LeadsTable},
        data() {
            return {

                name: 'block_leads',
                action: "leads/getBlockedLeads",
                deleteAction: "leads/deleteBlockedLeads",
                fields: [
                    {
                        key: "check",
                        label: "",
                        sortable: false,
                        tdClass: 'col-sm-1 col-1',
                        thClass: 'col-sm-1 col-1',
                    },
                    {
                        key: "first_name",
                        label: "User Name",
                        sortable: true,
                        tdClass: 'col-sm-2 col-2',
                        thClass: 'col-sm-2 col-2',
                    },
                    {
                        key: "email",
                        label: "User Email",
                        sortable: true,
                        tdClass: 'col-sm-2 col-2',
                        thClass: 'col-sm-2 col-2',
                    },
                    {
                        key: "website_url",
                        label: "Website",
                        sortable: true,
                        tdClass: 'col-sm-2 col-2',
                        thClass: 'col-sm-2 col-2',
                    },
                    {
                        key: "phone",
                        label: "Phone",
                        sortable: true,
                        tdClass: 'col-sm-2 col-2',
                        thClass: 'col-sm-2 col-2',
                    },
                    {
                        key: "created_date",
                        label: "Searched At",
                        sortable: true,
                        tdClass: 'col-sm-2 col-2',
                        thClass: 'col-sm-2 col-2',
                    },
                    {
                        key: "block_type",
                        label: "Block Type",
                        sortable: true,
                        tdClass: 'col-sm-1 col-1',
                        thClass: 'col-sm-1 col-1',
                    },
                ]
            }
        },


    }
</script>
