<template>
	<div class="settings-item">
		<div class="settings-item__img"><img src="../../../../../../shared/assets/styles/images/invoices.svg" alt=""></div>
		<h3 class="settings-item__title">Invoices</h3>
		<div class="settings-item__table" data-simplebar v-on:scroll="onTableScroll">
			<table ref="table">
				<thead>
				<tr>
					<th>Date</th>
					<th>Amount</th>
					<th></th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="invoice in invoices" :key="invoice.id">
					<td><strong>{{formatDate(invoice.created)}}</strong></td>
					<td>${{invoice.amount_paid / 100}}</td>
					<td><a class="blue-link" download :href="invoice.invoice_pdf">PDF</a></td>
				</tr>
				<tr v-if="!invoices.length">
					<td colspan="3"><i> There are no invoices to show </i></td>
				</tr>

				<tr v-if="loadingData">
					<td colspan="3"><Loading/></td>
				</tr>

				</tbody>
			</table>
		</div>
	</div>
</template>

<script>

    import moment from 'moment'
    import Loading from "../../../../../../shared/components/Loading";

    export default {
        name: "Invoices",
        components: {Loading},
        data() {
            return {
                lastId: null,
                loadingData: false,
				invoices: [],
				hasMore: true
			}
		},
        mounted() {
            this.getInvoices();

        },
        methods: {
            onTableScroll($event) {
                $event.preventDefault();
                let table = this.$refs.table;
                if (table) {
                    if ((table.getClientRects()[0].bottom - $event.target.getClientRects()[0].bottom) < 50) {
                        if (this.hasMore && !this.loadingData) {

                            this.getInvoices();
						}

                    }
                }
            },
            getInvoices() {
                this.loadingData = true;
                let data = null;
                if (this.hasMore && this.lastId ) {
                    data = this.lastId;
				}
                this.$store.dispatch('subscription/getInvoices', data )
                    .then((response) => {
                        if( response ) {
                            for (let i = 0; i < response.data.length; i++) {
                                this.invoices.push(response.data[i])
                            }
                            this.hasMore = response.has_more;

                            this.lastId = this.invoices.length ? this.invoices[this.invoices.length - 1]['id'] : null;

                        }
                        this.loadingData = false;

                    }).catch((err) => {
                    this.loadingData = false;
                    this.showErrorNotifications(err);
                })
			},
            formatDate(timestamp) {
                return moment(timestamp * 1000).format('LL')
            }
        },


    }
</script>
