<template>
	<div class="gr-table">
		<div class="leads-head">
			<div class="leads-pag">
				<div class="leads-pag__chose">

					<p class="leads-chose__txt">{{pageSizeLabel}}:</p>
					<b-dropdown id="dropdownLeadsPage" toggle-class="text-decoration-none" variant="link"
								:text="perPage.toString()" toggle-tag="a">
						<b-dropdown-item v-for="size in pageOptions" :key="`dropdown-${size}`"
										 @click="changePerPage(size)">{{size}}
						</b-dropdown-item>
					</b-dropdown>
				</div>
				<div class="leads-pag__nav">
					<b-pagination
							v-if="dataLoaded"
							v-model="currentPage"
							:total-rows="total"
							:per-page="perPage"
							@page-click="changePage"
							page-class="page"
							next-class="page"
							prev-class="page"
							ellipsis-class="page"
							last-class="page"
							first-number
							last-number
					></b-pagination>
				</div>
			</div>
			<div class="leads-btns">
				<button class="btn btn-primary" title="Delete" @click="deleteSelectedItems"><i class="icon-delete"></i>
				</button>
				<slot name="more_actions"></slot>
			</div>
		</div>
		<div class="leads-table">
			<b-table
					id="app-table"
					:items="myProvider"
					:fields="fields"
					:current-page="currentPage"
					:per-page="perPage"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:busy.sync="isBusy"
					@sort-changed="sortChanged"
					show-empty
					ref="table"
					sort-icon-right
					@row-clicked="myRowClickHandler"
					:hover="true"
					empty-text="There are no records to show yet">
				<template #head(check)>
					<b-form-checkbox
							:indeterminate.sync="indeterminateState"
							v-model="checkAll"
							@change="triggerSelectAll">
					</b-form-checkbox>
				</template>
				<template #head()="data">
					<span class="leads-table__link">{{data.label.toUpperCase()}}
						<template v-if="data.field.sortable">
							<i v-if="data.field.key === sortBy && sortDesc" class="icon-arrow-down"></i>
							<i v-if="data.field.key === sortBy && !sortDesc" style="transform: rotate(180deg);" class="icon-arrow-down"></i>
						</template>
					</span>
				</template>
				<template #cell(check)="row">
					<b-form-checkbox :key="`key-${row.item.id}`"
									 :checked="isItemChecked(row.item.id)"
									 @change="triggerSelection(row.item.id, $event)">
					</b-form-checkbox>
				</template>
				<template #cell(actions)="row">
					<slot name="actions" :item="row.item">

					</slot>
				</template>

			</b-table>
		</div>


	</div>

</template>

<script>
    export default {
        name: "app-table",
        props: {
            fields: {
                type: Array,
                required: true
            },
            checkedItems: {
                type: Array,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            pageSizeLabel: {
                type: String,
                required: false,
                default: 'Page size'
            },
            action: {
                type: String,
                required: true
            },
            deleteAction: {
                type: String,
                required: true
            }

        },
        data() {
            return {
                perPage: 10,
                pageOptions: [10, 20, 50, 100],
                currentPage: 1,
                total: 1,
                isBusy: false,
                dataLoaded: false,
                sortBy: 'id',
                sortDesc: true,
                checkAll: false,
                indeterminateState: false,
                items: []
            }
        },


        methods: {
            myProvider(ctx) {
                let sendData = {
                    page: ctx.currentPage,
                    limit: ctx.perPage,
                    sort_by: ctx.sortBy,
                    sort_desc: ctx.sortDesc | 0
                };

                return this.$store.dispatch(this.action, sendData).then((response) => {

                    const items = response.data;

                    this.indeterminateState = false;
                    this.total = response.total;
                    this.dataLoaded = true;
                    this.items = items;

                    this.setCheckAllCheckboxState(this.currentPageItems, this.checkedItems);
                    return items;
                }).catch((error) => {
                    this.showErrorNotifications(error);
                    return [];
                });

            },
            changePage(event, page = 1) {
                this.currentPage = page;

                //this.$root.$emit('scrollToTop', '#table');
                this.$router
                    .push({query: {...this.$route.query, page: page}})
                    .catch(() => {
                    });
            },
            changePerPage(size) {
                this.perPage = size;
                this.changePage();
            },
            sortChanged() {
                this.triggerSelectAll(false);
                this.changePage();
            },
            triggerSelectAll(checked) {

                if (this.currentPageItems.length > 0) {
                    this.currentPageItems.forEach((item) => {
                        this.$emit('checkUncheckItem', item.id, checked);

                    });
                }

            },
            myRowClickHandler(row) {
                const checked = this.isItemChecked(row.id);
                this.triggerSelection(row.id, !checked);
            },
            triggerSelection(itemId, checked) {
                this.$emit('checkUncheckItem', itemId, checked);

                this.$nextTick(() => {
                    this.setCheckAllCheckboxState(this.currentPageItems, this.checkedItems);
                })
            },
            isItemChecked(itemId) {
                return this.checkedItems.findIndex(item => item.id === itemId) !== -1;
            },
            setCheckAllCheckboxState(currentPageItems, checkedItems) {

                let notCheckedCount = currentPageItems.length;

                let indeterminate = false;

                if (notCheckedCount > 0) {
                    currentPageItems.forEach((pageItem) => {
                        if (checkedItems.findIndex(item => item.id === pageItem.id) !== -1) {
                            notCheckedCount--;
                        }
                    });

                    if (notCheckedCount === currentPageItems.length) {
                        indeterminate = false;
                        this.checkAll = false;

                    } else {

                        if (notCheckedCount > 0) {
                            indeterminate = true;
                            this.checkAll = true;
                        } else {
                            indeterminate = false;
                            this.checkAll = true;
                        }
                    }
                    this.$nextTick(() => {
                        this.indeterminateState = indeterminate;
                    });
                    return notCheckedCount;
                } else {
                    this.checkAll = false;
                    return false;
                }

            },
            deleteSelectedItems() {
                const checkedItemsCount = this.checkedItems.length;

                if (checkedItemsCount > 0 && confirm("Do you really want to delete " + checkedItemsCount + " lead(s) ?")) {
                    const sendData = [];
                    let items = this.checkedItems;
                    Object.keys(items).map(function (key) {
                        sendData.push(items[key].id)
                    });
                    this.$store.dispatch(this.deleteAction, sendData).then(() => {
                        this.showNotifications({message: 'Leads successfully deleted', type: 'success'});
                        if(this.currentPage > 1) {
                            this.changePage()
						} else {
                            this.$refs.table.refresh()
						}

                    }).catch((error) => {
                        this.showErrorNotifications(error);
                    });
                }
            },
			refresh() {
                this.$refs.table.refresh();
			}

        },
        computed: {
            currentPageItems() {
                const items = this.items;
                let pageItemsIdsNames = [];
                items.forEach(function (item) {
                    pageItemsIdsNames.push({
                        id: item.id,
                    });
                });
                return pageItemsIdsNames;
            },

        },
        created() {
            this.currentPage = this.$route.query.page || 1;

        },
    }
</script>
